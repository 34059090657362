<template>
  <v-container
    id="suppliers"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Sous-traitants"
    />
      <v-row>
        <v-col
          cols="12"
        >
          <v-card
            class="px-5 py-3"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher un sous-traitant"
              single-line
              hide-details
            />

            <div class="py-3" />
            <template v-slot:heading>
              <div class="display-2 font-weight-light">
                Sous-traitants
              </div>
            </template>
            <v-data-table
              class="table-suppliers"
              :headers="headers"
              :items="suppliers"
              :search="search"
              :items-per-page="15"
              @click:row="rowClick"
            >
              <template v-slot:[`item.raisonSociale`]="{ item }">
                {{ item.raisonSociale }}
                <v-tooltip
                  bottom
                  v-if="item.contactsAdministratif.length === 0 || item.contactsFacturation.length === 0 "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-1"
                      color="warning"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Contacts manquants</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <span
                  class="badge-chips-small"
                >
                  {{ item.type }}
                </span>
              </template>
              <template v-slot:[`item.adresse`]="{ item }">
                <span v-if="item.adresse && item.adresse.ville">
                  {{ item.adresse.ville }}
                </span>
                <span v-if="item.adresse && item.adresse.departement">
                  ({{ item.adresse.departement }})
                </span>
              </template>
            </v-data-table>
          </v-card>
          <div
            class="d-flex justify-end mb-3"
          >
            <v-btn
              color="primary"
              x-large
              :to="{ name: 'CreerSousTraitant'}"
            >
              <v-icon class="mr-2">mdi-plus-circle</v-icon>
              Créer un sous-traitant
            </v-btn>
          </div>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        search: '',
        headers: [
          {
            sortable: true,
            text: 'Raison sociale',
            value: 'raisonSociale',
          },
          {
            sortable: false,
            text: 'Adresse',
            value: 'adresse',
          },
        ],
        suppliers: [],
      }
    },

    created() {
      this.axios.get('/entreprisesSousTraitantes')
        .then((res) => {
          this.suppliers = [...res.data];
          this.suppliers.sort((a, b) => a.raisonSociale.localeCompare(b.raisonSociale));
        })

        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des sous-traitants', type: 'warn' });
        });
    },

    methods: {
      rowClick(item, row) {
        this.$router.push({ name: 'SousTraitantDetail', params: { id: item.id } });
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },

  }
</script>

<style scoped>
  #suppliers >>> .table-suppliers tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  #suppliers >>> .table-suppliers tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  #suppliers >>> .badge-chips-small {
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    border: solid 1px black;
  }
</style>
