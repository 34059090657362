var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"suppliers","fluid":"","tag":"section"}},[_c('base-v-component',{attrs:{"heading":"Sous-traitants"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-5 py-3",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Sous-traitants ")])]},proxy:true}])},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher un sous-traitant","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"py-3"}),_c('v-data-table',{staticClass:"table-suppliers",attrs:{"headers":_vm.headers,"items":_vm.suppliers,"search":_vm.search,"items-per-page":15},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.raisonSociale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.raisonSociale)+" "),(item.contactsAdministratif.length === 0 || item.contactsFacturation.length === 0 )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v("Contacts manquants")])]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"badge-chips-small"},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.adresse",fn:function(ref){
var item = ref.item;
return [(item.adresse && item.adresse.ville)?_c('span',[_vm._v(" "+_vm._s(item.adresse.ville)+" ")]):_vm._e(),(item.adresse && item.adresse.departement)?_c('span',[_vm._v(" ("+_vm._s(item.adresse.departement)+") ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{attrs:{"color":"primary","x-large":"","to":{ name: 'CreerSousTraitant'}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle")]),_vm._v(" Créer un sous-traitant ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }